import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "policies-list" }
const _hoisted_2 = { class: "card border-light health-leads-tasks" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "card-body filters-block" }
const _hoisted_5 = { class: "d-flex justify-content-between align-items-center leads-indicator spacing-t-15" }
const _hoisted_6 = { class: "leads nav-link alert bg-light-danger align-items-center leads-btn" }
const _hoisted_7 = { class: "card border-light mt-5" }
const _hoisted_8 = { class: "card-body card-custom-spacing-tblf-10" }
const _hoisted_9 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_socket = _resolveComponent("vc-socket")!
  const _component_vc_assigned_policies_first_layer = _resolveComponent("vc-assigned-policies-first-layer")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_vc_policies_table = _resolveComponent("vc-policies-table")!
  const _component_vc_policies_pagination = _resolveComponent("vc-policies-pagination")!
  const _component_vc_policy_documents = _resolveComponent("vc-policy-documents")!
  const _component_vc_policy_transfer_sales_agent = _resolveComponent("vc-policy-transfer-sales-agent")!
  const _component_vc_policy_transfer_underwriter = _resolveComponent("vc-policy-transfer-underwriter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_vc_socket, {
      channel: "health-policy-channel",
      listen: "Health.HealthPolicyCountSocketEvent",
      onAction: _ctx.silentReferesh
    }, null, 8, ["onAction"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_vc_assigned_policies_first_layer),
            _createVNode(_component_el_divider, { style: {"border-top":"2px solid #dcdfe6"} }),
            _createElementVNode("div", _hoisted_5, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-info text-italic fw-bold d-block" }, null, -1)),
              _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.lists ? _ctx.lists.total : 0) + " records selected", 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_vc_policies_table)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_vc_policies_pagination)
        ])
      ])
    ]),
    _createVNode(_component_vc_policy_documents),
    _createVNode(_component_vc_policy_transfer_sales_agent),
    _createVNode(_component_vc_policy_transfer_underwriter)
  ], 64))
}